import React from "react";
import { Container } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import Header from "../components/Header";
import { MQ_MOBILE } from "../constants";


const MainLayout = ({ content }: { content: React.ReactNode }) => {
 // let navigate = useNavigate(); 

  const isTabletOrMobile = useMediaQuery({ query: MQ_MOBILE });





  return (
    <>
      
      <div className="main-panel flex-column">
        <Header />
        <div className="content">
            
            {content}
        </div>
   
      </div>
    </>
  );
};

export default MainLayout;
