import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import  { useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
//import { PropagateLoader } from "react-spinners";
import "./SetRoomCode.scss";

export default function SetRoomCode({
    onConnect,
    onCancel,
}:{
    onConnect: Function,
    onCancel: Function,
}) {

    const [chars, setChars] = useState<Array<string>>(['','','','','','',])
    const [canConnect, setCanConnect] = useState<boolean>(false);

    const refs = useRef(new Array())


    const onCharEntered = (idx:number, value:string) =>{
        const newChars = [...chars];
        newChars[idx] = value.toLocaleUpperCase() ;
        setChars(newChars);
        var isOk = true;
        newChars.forEach( (c)=>{
            if (c === ''){
                isOk = false;
            }
        })
      
        if (idx < 5 && value !== ""){
            refs.current[idx+1].focus();
        }
        setCanConnect(isOk)
    }

    
    const onConnectToRoom = () =>{
        let code = chars.join('');
        code = code.substring(0, 3) + "-" + code.substring(3, code.length);
        console.log("Using code", code)
        onConnect(code)
    }


    return (
        <section className="set-room-code">
            <Row>
            <Col md={5}>
                <section className="instructions">
                    <h2>Subway Sensations</h2>
                    <h3>Connect Head Mounted Display <FontAwesomeIcon icon="vr-cardboard"  color="#2196f3" /></h3>
                    <p>Enter the six digit code provided by the patient.</p>
                </section>
            </Col>
            <Col md={7}>
                <section className="code-entry">
                    <h4>Enter Six Digit Code</h4>
                    <div className="code-wrapper">
                    {[...Array(6)].map((x, i) => {
                        const getRef = (element:any) => (refs.current.push(element))
                        return <div className="code-entry-box" >
                        <Form.Control
                            type="text"
                            id="character1" 
                            maxLength={1}
                            value={chars[i]}
                            onChange={(c)=>onCharEntered(i, c.target.value)}
                            ref={getRef}
                            />
                        </div>
                    })}
                            
{/*
                            <div className="code-entry-box" >
                                <Form.Control
                                    type="text"
                                    id="character2" 
                                    maxLength={1}
                                    value={chars[1]}
                                    onChange={(c)=>onCharEntered(1, c.target.value)}

                                />
                            </div>

                            <div className="code-entry-box" >
                                <Form.Control
                                    type="text"
                                    id="character3" 
                                    maxLength={1}
                                    value={chars[2]}
                                    onChange={(c)=>onCharEntered(2, c.target.value)}
                       
                                />
                            </div>
                            - 
                            <div className="code-entry-box" >
                                <Form.Control
                                    type="text"
                                    id="character4"
                                    maxLength={1} 
                                    value={chars[3]}
                                    onChange={(c)=>onCharEntered(3, c.target.value)}
                             
                                />
                            </div>

                            <div className="code-entry-box" >
                                <Form.Control
                                    type="text"
                                    id="character5" 
                                    maxLength={1}
                                    value={chars[4]}
                                    onChange={(c)=>onCharEntered(4, c.target.value)}
            
                                />
                            </div>

                            <div className="code-entry-box" >
                                <Form.Control
                                    type="text"
                                    id="character6" 
                                    maxLength={1}
                                    value={chars[5]}
                                    onChange={(c)=>onCharEntered(5, c.target.value)}
                                  
                                />
                            </div>
    */}
                    </div>
                    
                </section>
                <div className="controls mt-2">
                        <Button variant="curved" size="lg" onClick={()=>onConnectToRoom()} disabled={!canConnect}  >Connect</Button>
                        <Button variant="cancel" size="lg" onClick={()=>onCancel() } >Cancel</Button>
                </div>
                
                
            </Col>
        </Row>
        </section>)
}