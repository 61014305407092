import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, Row, Tab, Tabs } from "react-bootstrap";
import DifficultyStep from "./DifficultyStep";
import './DifficultyLevel.scss';

export default function DifficultyLevel({
    endIndex,
    defaultValues,
    disabled,
    onSave,
    onCancel
}:{
    endIndex: number,
    defaultValues: Array<string>,
    disabled?:boolean,
    onSave:Function,
    onCancel:Function,
}) {

    const startIndex = 0;

    const [tabKey, setTabKey] = useState<number>( 0 );
    const [tabLabels, setTabLabels] = useState<Array<string>>([]);
    const [selectedValues, setSelectedValues] = useState<Array<string>>(defaultValues)

    useEffect(()=>{
        const labels:Array<string> = [];
        for (var i=startIndex; i<=endIndex; i++){
            labels.push("Station " + i);
        }
        setTabLabels(labels);
    }, [startIndex, endIndex])


    
    const onDifficultyChanged = (value:string)=>{
        const newVals = [...selectedValues];
        newVals[tabKey] = value;
        setSelectedValues(newVals)
    }


    return (
        <section className="setup difficulty">
        <Row>
            <Col md={5}>
                <section className="instructions">
                    <h2>Subway Sensations</h2>
                    <h3>Difficulty Level <FontAwesomeIcon icon="gauge"  color="#2196f3" /></h3>
                    <p>There are {startIndex === 0 ? endIndex + 1 : endIndex } {endIndex === 1 ? "station" : "stations"} in total.<br/>
                        Choose the difficulty for each station.</p>
                </section>
            </Col>
            <Col md={7} className="tab-wrapper">
                {selectedValues.length > 0 && <Tabs
                    id="difficulty"
                    activeKey={ tabKey }
                    onSelect={(k) => setTabKey( Number(k!) )}
                    className="tabs"
                    justify
                    >
                        { tabLabels.map( (lbl, idx) => {
                            return <Tab eventKey={ idx } title={`Station ${idx+1}`} key={idx} >
                                <DifficultyStep
                                    active={selectedValues[idx]==='easy'}
                                    disabled={disabled}
                                    onSelected={onDifficultyChanged}
                                    value="easy"
                                    level="Easy"
                                    description="3 non-player characters (NPCs) on the car. Subway car noises. Ambient conversation. People walking on a platform. They will only behearing the subway car noises."
                                />
                                <DifficultyStep
                                    active={selectedValues[idx]==='medium'}
                                    disabled={disabled}
                                    onSelected={onDifficultyChanged}
                                    value="medium"
                                    level="Medium"
                                    description="3 non-player characters (NPCs) on the car. Subway car noises. Ambient conversation. People walking on a platform. They will only behearing the subway car noises."
                                />
                                <DifficultyStep
                                    active={selectedValues[idx]==='hard'}
                                    disabled={disabled}
                                    onSelected={onDifficultyChanged}
                                    value="hard"
                                    level="Hard"
                                    description="3 non-player characters (NPCs) on the car. Subway car noises. Ambient conversation. People walking on a platform. They will only behearing the subway car noises."
                                />
                            </Tab>
                        })}
                    
                </Tabs> }
                <div className="controls mt-3">
                    <Button variant="curved" size="lg" onClick={()=>onSave(selectedValues) } >Save</Button>
                    <Button variant="cancel" size="lg" onClick={()=>onCancel() } >Cancel</Button>
                </div>
            </Col>
        </Row>
        </section>)
}