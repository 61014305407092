import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import BoxedRangeSelector from "../BoxedRangeSelector";
import "./NumberStations.scss";

export default function NumberStations({
    defaultValue,
    onSave,
    onCancel,
}:{
    defaultValue: number,
    onSave:Function,
    onCancel:Function,
}) {


    const [selectedValue, setSelectedValue] = useState<number>(defaultValue)

    return (
    <section className="number-stations">
        <Row>
            <Col md={5}>
                <section className="instructions">
                    <h2>Subway Sensations</h2>
                    <h3>Number of Stations <FontAwesomeIcon icon="train-subway"  color="#2196f3" /></h3>
                    <p>Each stations is approximately 2 minutes long.</p>
                    <p>We suggest selecting 3 stations as a base.</p>
                </section>
            </Col>
            <Col md={7}>
                <div className="selector">
                    <BoxedRangeSelector startIndex={1} endIndex={6} defaultValue={defaultValue} 
                    disableAfter={3} disabled={false} onValueChanged={(val:number)=>setSelectedValue(val)} />
                </div>
                <div className="controls">
                    <Button variant="curved" size="lg" onClick={()=>onSave(selectedValue) } >Save</Button>
                    <Button variant="cancel" size="lg" onClick={()=>onCancel() } >Cancel</Button>
                </div>
            </Col>
        </Row>
    
    </section>)
}