import React, { useEffect, useState } from "react";
import { Col,  Row } from "react-bootstrap";
import ColyseusInteraction from "../components/ColyseusInteraction";
import AudioLevels, { AudioSetting } from "../components/subway-settings/AudioLevels";
import DifficultyLevel from "../components/subway-settings/DifficultyLevel";
import MainSetup from "../components/subway-settings/MainSetup";
import NumberStations from "../components/subway-settings/NumberStations";
import SetRoomCode from "../components/subway-settings/SetRoomCode";
import './SubwaySetup.scss';

export interface SubwayConfig {
    numStations: number,
    difficulty: Array<string>,
    audioLevels: Array<AudioSetting>,
}

const defaultSettings:SubwayConfig = {
    numStations: 3,
    difficulty: [ 'easy', 'medium', 'hard', 'medium', 'hard', 'hard', 'hard'],
    audioLevels: [
        {
            level: "all",
            id: "tts",
            value: true,
        },
        {
            level: "low",
            id: "subwayMechanicalNoises",
            value: 30,
        },
        {
            level: "low",
            id: "subwayDoorNoises",
            value: 30,
        },
        {
            level: "low",
            id: "subwayAnnouncer",
            value: 30,
        },
        {
            level: "medium",
            id: "subwayMechanicalNoises",
            value: 60,
        },
        {
            level: "medium",
            id: "subwayDoorNoises",
            value: 50,
        },
        {
            level: "medium",
            id: "subwayAnnouncer",
            value: 40,
        },
        {
            level: "high",
            id: "subwayMechanicalNoises",
            value: 100,
        },
        {
            level: "high",
            id: "subwayDoorNoises",
            value: 80,
        },
        {
            level: "high",
            id: "subwayAnnouncer",
            value: 100,
        },
    ]
    
}

export default function Dashboard({token}:{token:string|undefined}) {

    const [activeCategory, setActiveCategory] = useState<string>("menu");
    const [currentSettings, setCurrentSettings] = useState<SubwayConfig>(defaultSettings);
    const [roomId, setRoomId] = useState<string>("");
   
    const onNumStationsUpdated = (num:number) =>{
        const settings = {...currentSettings}
        settings.numStations = num;
        setCurrentSettings(settings)
        setActiveCategory('menu')
    }

    const onDifficultyUpdated = (levels:Array<string> ) => {
        const settings = {...currentSettings}
        settings.difficulty = levels;
        setCurrentSettings(settings)
        setActiveCategory('menu')
    }


    const onAudioLevelsUpdated = (levels:Array<AudioSetting>)=>{
        const settings = {...currentSettings}
        settings.audioLevels = levels;
        setCurrentSettings(settings)
        setActiveCategory('menu')
    }


    const onCancel = ()=>{
        setActiveCategory('menu')
    }

    const onConnect= (roomCode:string)=>{
        setRoomId(roomCode);
        setActiveCategory('network');
    }

    useEffect( ()=> {
        console.log("\n\n>>> Token updated", token);
    }, [token]);


    return (
        <div className='setup'>
           
                { activeCategory === 'menu' && <MainSetup  onCategorySelected={(category:string)=>setActiveCategory(category)} /> }
                { activeCategory === 'numberStations' && <NumberStations 
                    defaultValue={currentSettings.numStations}  
                    onSave={(num:number)=>onNumStationsUpdated(num) }
                    onCancel={()=>onCancel() } 
                    /> }
                { activeCategory === 'difficulty' && <DifficultyLevel  
                        endIndex={currentSettings.numStations-1} 
                        onSave={(level:any)=>onDifficultyUpdated(level)} 
                        defaultValues={currentSettings.difficulty}  
                        onCancel={()=>onCancel() } 
                    /> }
                { activeCategory === 'audioLevels' && <AudioLevels defaultSettings={currentSettings.audioLevels} 
                            onSave={(settings:Array<AudioSetting>)=>onAudioLevelsUpdated(settings)}
                            onCancel={()=>onCancel() }     
                        />  }
                { activeCategory === 'code' &&  <SetRoomCode
                        onCancel={()=>onCancel()}
                        onConnect={((roomCode:string)=>onConnect(roomCode) )}
                    />}
                { activeCategory === 'network' &&  <ColyseusInteraction
                    roomCode={roomId}
                    token={token}
                    onEndNetwork={(nextStep:string)=>setActiveCategory(nextStep) }
                />}
            
        </div>)
}