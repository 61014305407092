import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import './BoxedRangeSelector.scss';

export default function BoxedRangeSelector({
    startIndex,
    endIndex,
    defaultValue,
    disableAfter,
    disabled,
    onValueChanged,
}:{
    startIndex: number,
    endIndex: number,
    defaultValue: number,
    disableAfter?: number,
    disabled?:boolean,
    onValueChanged:Function,
}) {

    const [selectedValue, setSelectedValue] = useState<number>(defaultValue);
    const [boxRange, setBoxRange] = useState<Array<number>>([]);

    const onBoxClicked = (i:number)=>{
        if (! disabled){
            setSelectedValue(i);
            onValueChanged(i);
        }
    }

    useEffect(()=>{
        setSelectedValue(defaultValue);
        const range:Array<number> = [];
        for (var i=startIndex;i<=endIndex; i++){
            range.push(i);
        }
        setBoxRange(range);
    }, [defaultValue, setSelectedValue])

    return (
    <div className={disabled ? "boxed-range-selector disabled" : "boxed-range-selector"} >
     { boxRange.map( (i)=>{
        return <div key={i}  className={ `${selectedValue === i ? 'option-box selected' : 'option-box'} ${ disableAfter && i > disableAfter ? 'disabled'  : ''}`} >
            <Button variant="link" onClick={()=>onBoxClicked(i)} 
                disabled={ disableAfter ? i > disableAfter : false } 
            >{i}</Button>
        </div>
     }) }
    </div>)
}