import React from "react";
import { Button } from "react-bootstrap";
import './DifficultyStep.scss';

export default function DifficultyStep({
    level,
    description,
    value,
    active,
    onSelected,
    disabled,
    
}:{
    level:string,
    description: string,
    value: string,
    active:boolean,
    onSelected:Function,
    disabled?:boolean
  
}) {


    return (
        <Button onClick={()=>onSelected(value)} variant="difficulty" className={active ? "active":" " + ((disabled===true) ? "disabled" : "") }>
            <h4>{level}</h4><p>{description}</p>
        </Button>)
}