import React from "react";
import logo from './../assets/subway-sensations-logo.png';
import './Header.scss';

export default function Header() {

    return (
        <>
            <header>
                <h1>Subway Sensations Facilitator App</h1>
            </header>
        </>)
}