import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.scss';
import ColyseusDemo from './colyseus/ColyseusDemo';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ProtectedRoute from './components/ProtectedRoute';
import MainLayout from './layouts/MainLayout';
import Login from './pages/Login';
import SubwaySetup from './pages/SubwaySetup';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faGauge, faTrainSubway, faVolumeOff, faVrCardboard, faMessage, faPlay } from '@fortawesome/free-solid-svg-icons'

function App() {
  library.add(faTrainSubway, faGauge, faVolumeOff, faVrCardboard, faMessage, faPlay)

  const [token, setToken] = useState<string|undefined>();

  // iframe message handling:
  useEffect(() => {
    const handleMessage = (event:any) => {
        // Check the origin of the message for security
        // Replace 'http://example.com' with your domain
        if (event.origin !== "http://example.com") {
        //    return;
        }

        if (event.data.source !== "react-devtools-content-script"){
          console.log("\n FA: got a response from the parent.", event.data);
          if (event.data === undefined ){
            setToken("UNSET");
          }else{
            setToken(event.data.token);
          }
        }
    };

    // Listen for messages
    window.addEventListener("message", handleMessage);

    // Tell parent iframe we're ready for the token:
    console.log("\n FA: About to request token with { cmd: 'getToken' }");
    window.parent.postMessage({ cmd: "getToken" }, "*")

    // Cleanup function
    return () => {
        window.removeEventListener("message", handleMessage);
    };
}, []); // Empty dependency array means this runs once on mount


  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute
                children={<MainLayout  content={<SubwaySetup token={token} />} />}
              />
            }
          />
          
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
