import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import SettingSelectBar from "./SettingSelectBar";

export default function MainSetup({
    onCategorySelected,
}:{
    onCategorySelected:Function
}) {



    return (
    <section className="setup main">
        <Row>
            <Col md={5}>
                <section className="instructions">
                    <h2>Subway Sensations</h2>
                    <p>Welcome to Subway Sensations!
                        <br/>Let's get you setup to begin your session.
                    </p>
                    <p>
                        You can modify the settings on the right, or click Begin to use the defaults.
                    </p>
                </section>
            </Col>
            <Col md={7}>
        
                    <SettingSelectBar icon="train-subway" label="Number of Stations" onEditClicked={()=>onCategorySelected('numberStations') }/>
                    <SettingSelectBar icon="gauge" label="Difficulty Level"  onEditClicked={()=>onCategorySelected('difficulty')} />
                    <SettingSelectBar icon="volume-off" label="Audio Level" onEditClicked={()=>onCategorySelected('audioLevels')}/>
                    {/*<SettingSelectBar icon="vr-cardboard" label="This is a label" />*/}
            </Col>
        </Row>
        <Row>
            <Col md={12} className="controls">
                <Button variant="curved" size="lg" onClick={()=>onCategorySelected('code')} >Begin</Button>
            </Col>
        </Row>
    </section>)
}