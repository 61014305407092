import React, { useState } from "react";
import { Form } from "react-bootstrap";
import './SliderBox.scss';

export default function SliderBox({
    label,
    defaultValue,
    hasBackground,
    onValueChange
}:{
    label: string,
    defaultValue: number,
    hasBackground?: boolean,
    onValueChange:Function,
}) {

const [selectedValue, setSelectedValue] = useState<number>(defaultValue);

const onChange = (val:number)=>{
    setSelectedValue(val);
    onValueChange(val);
}

return (
    <div className={`slider-box ${hasBackground === false ? '' : 'background'  }`}>
        <Form.Label>{label}</Form.Label>
        <Form.Range value={selectedValue} onChange={(v)=>onChange( Number(v.target.value) )} />
        <p>{selectedValue}%</p>
    </div>)
}