import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
//import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(true);
   
   
    return <React.Fragment>
        { isAuthenticated ? children : <Navigate to="/login" replace /> } 
    </React.Fragment>
  }
  
  export default ProtectedRoute