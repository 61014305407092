import React, { useState } from "react";
import { Button } from "react-bootstrap";
import './ButtonBar.scss';

export default function ButtonBar({
    defaultSelected,
    onChange,
}:{
    defaultSelected: boolean,
    onChange:Function,
}) {

    const [currentValue, setCurrentValue] = useState<boolean>(defaultSelected);

    const onValueChange = (val:boolean)=>{
        setCurrentValue(val);
        onChange(val);
    }

    return (
        <div className="button-bar">
            <Button variant="square" onClick={()=>onValueChange(true)} className={currentValue ? 'active' : ''}  >On</Button>
            <Button variant="square" onClick={()=>onValueChange(false)} className={!currentValue ? 'active' : ''} >Off</Button>
        </div>)
}