import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import ButtonBar from "./ButtonBar";
import SliderBox from "./SliderBox";



export interface AudioSetting {
    level: string,
    id: string,
    value: number|boolean,
}



export default function AudioLevels({
    defaultSettings,
    onSave,
    onCancel,
}:{
    defaultSettings: Array<AudioSetting>,
    onSave:Function,
    onCancel:Function,
}) {
    
   
    const [currentSettings, setCurrentSettings] = useState<Array<AudioSetting>>(defaultSettings);


    /**
     * Changes the stored settings
     * @param level low / medium / high
     * @param id Control ID, eg subwayMechanicalNoises
     * @param value 
     */
    const onValueChange = (level:string, id:string, value:number|boolean)=>{
        const settings = [...currentSettings];

        const idx = settings.findIndex( s => s.level === level && s.id === id);
        if (idx !== undefined){
            settings[idx].value = value;
        }
        setCurrentSettings(settings);
    }



    const getSetting = (level:string, id:string):any =>{
        const setting = currentSettings.find( s => s.level === level && s.id === id);
        if (setting === undefined){
            throw new Error("Setting level/key not found.") 
        }
        return setting.value;
    }

   

    return (
        <section className="audioLevels">
             <Row>
                <Col md={5}>
                    <section className="instructions">
                        <h2>Subway Sensations</h2>
                        <h3>Audio Levels <FontAwesomeIcon icon="volume-off"  color="#2196f3" /></h3>
                        <p>Adjust all audio levels for the experience such as:</p>
                        <h5>Text-to-Speech</h5>
                        <p>
                            All text durin ghte experience will be converted to audio. For example, the
                            tutorial instructions will be 'read aloud'.
                        </p>
                        <h5>Sound Effects (SFX)</h5>
                        <p>Adjust sound levels according to the chosen difficulty levels.</p>
                    </section>
                </Col>
                <Col md={7} className="levels">
                    <Form>
                        <Form.Group as={Row} className="mb-4" controlId="volume-level">
                            <Form.Label  >Text-to-Speech <FontAwesomeIcon icon="message"  color="#2196f3" /></Form.Label>
                                <Col md={12} className="mb-4">
                                    <ButtonBar defaultSelected={true} onChange={(val:boolean)=>{onValueChange('all', 'tts', val)}}  />
                                </Col>
                        </Form.Group>

                        <h3 className="mt-4">Sound Effects (SFX) <FontAwesomeIcon icon="play"  color="#2196f3" /> </h3>

                        
                        <Form.Group as={Row} className="mb-3 mt-8" controlId="volume-level">
                            <Form.Label  className="mb-3 mt-4">Low</Form.Label>
                                <Col md={12}>
                                    <SliderBox defaultValue={getSetting("low", "subwayMechanicalNoises")} label="Subway mechanical noises" 
                                        onValueChange={(val:number)=>onValueChange('low', 'subwayMechanicalNoises', val)}  />
                                    <SliderBox defaultValue={getSetting("low", "subwayDoorNoises")} label="Subway door noises" hasBackground={false}
                                        onValueChange={(val:number)=>onValueChange('low', 'subwayDoorNoises', val)} />
                                    <SliderBox defaultValue={getSetting("low", "subwayAnnouncer")} label="Subway announcer"
                                        onValueChange={(val:number)=>onValueChange('low', 'subwayAnnouncer', val)} />
                                </Col>
                        </Form.Group>

                     
                        <Form.Group as={Row} className="mb-3 mt-8" controlId="volume-level">
                            <Form.Label className="mb-3 mt-4" >Medium </Form.Label>
                                <Col md={12}>
                                    <SliderBox defaultValue={getSetting("medium", "subwayMechanicalNoises")} label="Subway mechanical noises" 
                                        onValueChange={(val:number)=>onValueChange('medium', 'subwayMechanicalNoises', val)}  />
                                    <SliderBox defaultValue={getSetting("medium", "subwayDoorNoises")} label="Subway door noises" hasBackground={false}
                                        onValueChange={(val:number)=>onValueChange('medium', 'subwayDoorNoises', val)} />
                                    <SliderBox defaultValue={getSetting("medium", "subwayAnnouncer")} label="Subway announcer"
                                        onValueChange={(val:number)=>onValueChange('medium', 'subwayAnnouncer', val)} />
                                </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3 mt-8" controlId="volume-level">
                            <Form.Label className="mb-3 mt-4" >High </Form.Label>
                                <Col md={12}>
                                    <SliderBox defaultValue={getSetting("high", "subwayMechanicalNoises")} label="Subway mechanical noises" 
                                        onValueChange={(val:number)=>onValueChange('high', 'subwayMechanicalNoises', val)}  />
                                    <SliderBox defaultValue={getSetting("high", "subwayDoorNoises")} label="Subway door noises" hasBackground={false}
                                        onValueChange={(val:number)=>onValueChange('high', 'subwayDoorNoises', val)} />
                                    <SliderBox defaultValue={getSetting("high", "subwayAnnouncer")} label="Subway announcer"
                                        onValueChange={(val:number)=>onValueChange('high', 'subwayAnnouncer', val)} />

                                    <div className="controls mt-3">
                                        <Button variant="curved" size="lg" onClick={()=>onSave(currentSettings) } >Save</Button>
                                        <Button variant="cancel" size="lg" onClick={()=>onCancel() } >Cancel</Button>
                                    </div>
                                </Col>
                        </Form.Group>
                        
                    </Form>
                </Col>
            </Row>
            
        </section>)
}