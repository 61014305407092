import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col } from "react-bootstrap";
import "./SettingSelectBar.scss";

export default function SettingSelectBar(
    {
        icon,
        label,
        onEditClicked,
    }:{
        icon:IconProp,
        label:string,
        onEditClicked:Function,
    }
) {

    return (
    <div className="setting-select-bar">
        <FontAwesomeIcon icon={icon} size="2xl" color="#2196f3" />
        <label>{label}</label>
        <Button variant="curved" size="sm" color="#2196f3" onClick={()=>onEditClicked() } >Edit</Button>
    </div>)
}